import * as React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/ui/Seo';
import ProgramDetailLayout from '../components/ui/Layout/ProgramDetailLayout';
import ProgramName from '../components/ProgramDetail/ProgramName';
import FeatureImage from '../components/ProgramDetail/FeatureImage';

import SingleLine from '../components/ProgramDetail/Responses/SingleLine';
import List2Col from '../components/ProgramDetail/Responses/List2Col';
import SingleLineList from '../components/ProgramDetail/Responses/SingleLineList';
import SingleLine3Col from '../components/ProgramDetail/Responses/SingleLine3Col';
import List1Col from '../components/ProgramDetail/Responses/List1Col';
import SuccessStories from '../components/ProgramDetail/Responses/SuccessStories';
import { DIRECT_SERVICES_MENU } from '../utils/constants';
import * as DSIcon from '../svgs/direct-service.svg';
import classnames from 'classnames';
import s from './ProgramDetail.module.scss';
import VideoPlayer from '../components/ui/VideoPlayer/VideoPlayer';
import SingleLineLinkList from '../components/ProgramDetail/Responses/SingleLineLinkList';
import Quote from '../components/ProgramDetail/Responses/Quote';
import YesNo from '../components/ProgramDetail/Responses/YesNo';
import TheoryOfChange from '../components/ProgramDetail/TheoryOfChange';
import LineAndDownArrow from '../components/ui/LineAndDownArrow/LineAndDownArrow';

const DirectServicesProgramDetail = ({
  // keep to pass variables set in gatsby-node.js
  pageContext,
  data,
}: {
  pageContext: { programName: string; slug: string };
  data: {
    allContentfulDirectServicesQuestions: { edges: any };
    allContentfulProgram: { edges: any };
    allContentfulVideo: { edges: any };
  };
}) => {
  if (
    data === undefined ||
    !data.allContentfulDirectServicesQuestions ||
    data.allContentfulDirectServicesQuestions.edges.length === 0 ||
    !data.allContentfulProgram ||
    data.allContentfulProgram.edges.length === 0
  ) {
    return null;
  }
  let survey = data.allContentfulDirectServicesQuestions.edges[0].node;
  let program = data.allContentfulProgram.edges[0].node;
  let video =
    data.allContentfulVideo.edges[0] &&
    data.allContentfulVideo.edges[0].node.video.localFile.url;
  let links = program.linksAndDownloads;
  let clientQuote =
    program.quotes && program.quotes[0] && program.quotes[0].quote.quote;
  let quoteSource = program.quotes && program.quotes[0].source;

  const otherShortTerm =
    survey.shortTermOutcomesOther &&
    survey.shortTermOutcomesOther.shortTermOutcomesOther;

  const shortTermOutcomes = [survey.shortTermOutcomes, otherShortTerm];
  const otherLongTerm =
    survey.longTermOutcomesOther &&
    survey.longTermOutcomesOther.longTermOutcomesOther;
  const longTermOutcomesMapped = Array.isArray(survey.longTermOutcomes)
    ? [...survey.longTermOutcomes, otherLongTerm]
    : [survey.longTermOutcomes, otherLongTerm];

  const hasImages = (): boolean => {
    return program.imageLinks !== null;
  };

  const govLaws =
    survey.mainGovernmentLaws && survey.mainGovernmentLaws.length > 0
      ? survey.mainGovernmentLaws.map((t: any) => t.law.law.toString())
      : false;

  const [laws] = React.useState(govLaws);

  const hasVideos = (): boolean => {
    return (
      video !== undefined ||
      (program.videoLinks && program.videoLinks.length > 0)
    );
  };

  function getVideo() {
    let vid;
    if (video) {
      vid = video;
    } else {
      vid = program.videoLinks[0];
    }

    return vid;
  }

  // console.table('Survey ', survey);
  // console.table('Program ', program);

  return (
    <ProgramDetailLayout menuItems={DIRECT_SERVICES_MENU}>
      <SEO title={survey.programName} />
      <p className="link-icon text-right">
        <a style={{ fontSize: '24px' }}>
          <DSIcon style={{ marginRight: '16px' }} />
          Direct Service
          <br />
          <span style={{ fontSize: '18px' }}>{program.programSubType}</span>
        </a>
      </p>
      <article className={'module-program'}>
        <header style={{ borderBottomWidth: 0 }}>
          <ProgramName programName={survey.programName} />
        </header>
        {hasImages() && !hasVideos() && (
          <FeatureImage
            programName={survey.programName}
            images={program.imageLinks}
          />
        )}
        {hasVideos() && <VideoPlayer url={getVideo()} />}

        {clientQuote && <Quote quote={clientQuote} source={quoteSource} />}

        {/* PROGRAM PROFILE */}
        <div id={'profile'}>
          <p className={s.section__title}>Program Profile</p>
          {program.implementingOrganization && (
            <SingleLine
              question={'Implementing organization'}
              answer={program.implementingOrganization}
            />
          )}

          <SingleLine3Col
            headings={['Country', 'Headquarters', 'Start Year']}
            responses={[
              program.countryRegion,
              program.headquarters,
              program.startYear,
            ]}
          />

          {program.website && (
            <SingleLine question={'website'} answer={program.website} link />
          )}

          {program.description && program.description.description && (
            <SingleLine
              question={'Program Description'}
              answer={program.description.description}
            />
          )}

          {/* THE BIG PICTURE */}
        </div>
        <div id={'bigPicture'}>
          <p className={s.section__title}>The Big Picture</p>
          {survey.childContentfulDirectServicesQuestionsProblemAddressedTextNode
            .problemAddressed && (
            <SingleLine
              question={'Problem Addressed'}
              answer={
                survey
                  .childContentfulDirectServicesQuestionsProblemAddressedTextNode
                  .problemAddressed
              }
            />
          )}
          {survey.programObjective && (
            <SingleLine
              question={'Program Objective'}
              answer={survey.programObjective}
            />
          )}
          {survey.childContentfulDirectServicesQuestionsWhatWouldMakeYourWorkUnnecessaryTextNode && (
            <SingleLine
              question={'What would make your work unnecessary?'}
              answer={
                survey
                  .childContentfulDirectServicesQuestionsWhatWouldMakeYourWorkUnnecessaryTextNode
                  .whatWouldMakeYourWorkUnnecessary
              }
            />
          )}
          {survey.mainPracticalChallengesFaced && (
            <List2Col
              title={'Main Practical Challenges Faced'}
              responses={survey.mainPracticalChallengesFaced}
            />
          )}
          {laws && (
            <SingleLineList
              question={
                'Main government laws, policies, or programs that have led to positive change in the last 40 years:'
              }
              responses={laws}
            />
          )}
        </div>
        {/* Program Beneficiaries & Target Groups */}
        <div id={'beneficiariesAndTargets'}>
          <p className={s.section__title}>
            Program Beneficiaries & Target Groups
          </p>
          {survey.mainBeneficiaries && (
            <SingleLineList
              question={'Main Beneficiaries'}
              responses={survey.mainBeneficiaries}
            />
          )}
          {survey.targetedGroups && (
            <SingleLineList
              question={'Targeted Groups'}
              responses={survey.targetedGroups}
            />
          )}
        </div>
        {/* THEORY OF CHANGE */}
        <div id={'theoryOfChange'}>
          <p className={s.section__title}>Theory of Change</p>
          <TheoryOfChange />
          {survey.services && (
            <SingleLineList question={'Services'} responses={survey.services} />
          )}
          {survey.outputs && (
            <>
              <LineAndDownArrow />
              <List2Col title={'Outputs'} responses={survey.outputs} />
            </>
          )}
          {shortTermOutcomes && (
            <>
              <LineAndDownArrow />
              <List2Col
                title={'Short-Term Outcomes'}
                responses={shortTermOutcomes}
              />
            </>
          )}
          {longTermOutcomesMapped && (
            <>
              <LineAndDownArrow />
              <List2Col
                title={'Long-Term Outcomes'}
                responses={longTermOutcomesMapped}
              />
            </>
          )}
          {survey.longTermChange && (
            <>
              <LineAndDownArrow />
              <SingleLine
                question={'Long-Term Change'}
                answer={survey.longTermChange}
              />
            </>
          )}
          {survey.activitiesprogramFocus && (
            <List2Col
              title={'Activities/Program Focus'}
              responses={survey.activitiesprogramFocus}
            />
          )}
          {survey.strategiesToInfluencePolicy && (
            <SingleLineList
              question={'Strategies to influence policy'}
              responses={survey.strategiesToInfluencePolicy}
            />
          )}
          {survey.programRationale && (
            <SingleLineList
              question={'Program Rational'}
              responses={survey.programRationale}
            />
          )}
          {survey.ingredientsForSuccess && (
            <>
              <LineAndDownArrow />
              <SingleLine
                question={'Ingredients for Success'}
                answer={survey.ingredientsForSuccess.ingredientsForSuccess}
              />
            </>
          )}
        </div>
        {/* Implementation */}
        <div id={'implementation'}>
          <p className={classnames(s.section__title, s.bottomBorder)}>
            Implementation
          </p>
          {/* {survey.yearsImplemented && (
            <SingleLine
              question={'Years Implemented'}
              answer={survey.yearsImplemented}
            />
          )} */}
          <h3>Scope/Reach</h3>
          <SingleLine3Col
            headings={[
              'Estimate % of total target population in your country participating',
              'Estimate % of total incarcerated or formerly incarcerated population participating',
              'Participants/beneficiaries in 2017',
            ]}
            responses={[
              survey.percentOfTotalTargetPopulationParticipating,
              survey.percentTotalIndividualsInvolvedWithProgram,
              survey.participantsbeneficiariesIn2017,
            ]}
          />
          {survey.outputsCompletedIn20172018 && (
            <List2Col
              title={'Outputs Completed in 2017/2018'}
              responses={survey.outputsCompletedIn20172018}
            />
          )}
          {survey.averageLengthOfTimeForServices && (
            <SingleLineList
              question={'Average length of time for services'}
              responses={survey.averageLengthOfTimeForServices}
            />
          )}

          {hasImages() && hasVideos() && (
            <FeatureImage
              programName={survey.programName}
              images={program.imageLinks}
            />
          )}
        </div>
        {/* Cost */}
        <div id={'cost'}>
          <p className={classnames(s.section__title, s.bottomBorder)}>Cost</p>
          <SingleLine3Col
            headings={[
              'Annual Program Budget',
              'Budget Year',
              'Main Funding Source',
            ]}
            responses={[
              survey.annualProgramBudget,
              survey.budgetYear,
              survey.mainFundingSource,
            ]}
          />
        </div>
        {/* Evaluating Success */}
        <div id={'evaluatingSuccess'}>
          <p className={s.section__title}>Evaluating Success</p>
          {survey.dataCollected && (
            <List2Col
              title={'Data Collected'}
              responses={survey.dataCollected}
            />
          )}
          {survey.indicatorsTracked && (
            <List2Col
              title={'Indicators Tracked'}
              responses={survey.indicatorsTracked}
            />
          )}
          <YesNo
            question={'Evaluations Conducted'}
            answer={survey.evaluationConducted}
          />
          {survey.typeOfEvaluationsConducted && (
            <List1Col
              title={'Type of Evaluations Conducted'}
              responses={survey.typeOfEvaluationsConducted}
            />
          )}
          {survey.childContentfulDirectServicesQuestionsEvaluationFindingsTextNode && (
            <SingleLine
              question={'Evaluation Findings'}
              answer={
                survey
                  .childContentfulDirectServicesQuestionsEvaluationFindingsTextNode
                  .evaluationFindings
              }
            />
          )}
          {survey.evaluationWeblinks && (
            <SingleLineLinkList
              question={'Evaluation links'}
              responses={[survey.evaluationWeblinks]}
            />
          )}
          {survey.successStories && survey.successStories.length > 0 && (
            <SuccessStories
              question={'Success Stories'}
              stories={survey.successStories}
            />
          )}
        </div>
        <div id={'additionalMaterials'}>
          {/* Additional Materials */}
          {links && (
            <>
              <p className={s.section__title}>Additional Materials</p>
              <SingleLineLinkList
                question={'Links & Downloads'}
                responses={links ? links : 'N/A'}
              />
            </>
          )}
        </div>
      </article>

      <Link to="/">
        <p style={{ color: 'black' }}>Go back to the homepage</p>
      </Link>
    </ProgramDetailLayout>
  );
};

export default DirectServicesProgramDetail;

export const query = graphql`
  query DirectServicesProgramDetailQuery($programName: String!) {
    allContentfulProgram(filter: { programName: { eq: $programName } }) {
      edges {
        node {
          imageLinks
          videoLinks
          socialMedia
          website
          linksAndDownloads
          implementingOrganization
          countryRegion
          headquarters
          startYear
          programType
          programSubType
          description {
            description
          }
          quotes {
            quote {
              quote
            }
            source
            sourceTitle
          }
        }
      }
    }
    allContentfulVideo(
      filter: { program: { programName: { eq: $programName } } }
    ) {
      edges {
        node {
          video {
            localFile {
              url
            }
          }
        }
      }
    }
    allContentfulDirectServicesQuestions(
      filter: { programName: { eq: $programName } }
    ) {
      edges {
        node {
          programName
          childContentfulDirectServicesQuestionsProblemAddressedTextNode {
            problemAddressed
          }
          programObjective
          childContentfulDirectServicesQuestionsWhatWouldMakeYourWorkUnnecessaryTextNode {
            whatWouldMakeYourWorkUnnecessary
          }
          mainGovernmentLaws {
            law {
              law
            }
          }
          mainPracticalChallengesFaced
          mainBeneficiaries
          targetedGroups
          services
          outputs
          shortTermOutcomes
          shortTermOutcomesOther {
            shortTermOutcomesOther
          }
          longTermOutcomes
          longTermOutcomesOther {
            longTermOutcomesOther
          }
          longTermChange
          programRationale
          ingredientsForSuccess {
            ingredientsForSuccess
          }
          percentOfTotalTargetPopulationParticipating
          percentStaffIncarceratedformerlyIncarcerated
          outputsCompletedIn20172018
          participantsbeneficiariesIn2017
          averageLengthOfTimeForServices
          percentBoardOfDirectorsIncarceratedformerlyI
          percentIncarceratedformerlyIncarceratedOther
          percentStaffIncarceratedformerlyIncarcerated
          numberOfStaff
          numberOfOtherStaff
          totalProgramSize
          boardOfDirectorsSize
          percentTotalIndividualsInvolvedWithProgram
          annualProgramBudget
          budgetYear
          mainFundingSource
          dataCollected
          indicatorsTracked
          evaluationConducted
          evaluationWeblinks
          evaluationFindings {
            evaluationFindings
          }
          typeOfEvaluationsConducted
          typeOfEvaluationsOther {
            typeOfEvaluationsOther
          }
        }
      }
    }
  }
`;
